import { format } from "date-fns";
import { pt } from "date-fns/locale";
import * as qs from "query-string";
import React, { Component } from "react";
import "../assets/theme.css";
import Logo from "./Logo/Logo";
import DateSlider from "./Options/DateSlider";
import Partner from "./Partner/Partner";
import SelectedStations from "./Selection/SelectedStations";
import { Engine } from "./engine";

// Some config
const UseDateSlider = false;
const DateSliderRangeInMilliseconds = 24 * 60 * 60 * 1000; // 24 hours
const TleIntervalInMilliseconds = 1 * 60 * 60 * 1000; // 1 hour

const defaultTimer = 5;

class App extends Component {
  state = {
    time: new Date(),
    selected: null,
    stations: [],
    query: null,
    queryObjectCount: 0,
    initialDate: new Date().getTime(),
    currentDate: new Date().getTime(),
    referenceFrame: UseDateSlider ? 2 : 1,
  };

  componentDidMount() {
    this.engine = new Engine();
    this.engine.referenceFrame = this.state.referenceFrame;
    this.engine.initialize(this.el, {
      onStationClicked: this.handleStationClicked,
    });

    this.addStations();

    if (!this.state.selected) this.addStations();
    setInterval(() => {
      this.handleRemoveSelected(this.state.selected);
      this.addStations();
    }, TleIntervalInMilliseconds);
    this.engine.updateAllPositions(new Date());

    setInterval(this.handleTimer, defaultTimer);
    setInterval(this.updateTime);
  }

  componentWillUnmount() {
    this.engine.dispose();
  }

  processQuery = (stations) => {
    const q = window.location.search;
    if (!q) return;

    const params = qs.parse(q);

    if (params.ss) {
      const selectedIds = params.ss.split(",");
      if (!selectedIds || selectedIds.length === 0) return;

      selectedIds.forEach((id) => {
        const station = this.findStationById(stations, id);
        if (station) this.selectStation(station);
      });
    }

    if (params.highlight) {
      const query = params.highlight;
      const matches = this.queryStationsByName(stations, query);
      matches.forEach((st) => this.engine.highlightStation(st));
      this.setState((prevState) => ({
        ...prevState,
        query,
        queryObjectCount: matches.length,
      }));
    }
  };

  queryStationsByName = (stations, query) => {
    query = query.toLowerCase();
    return stations.filter((st) => st.name.toLowerCase().indexOf(query) > -1);
  };

  findStationById = (stations, id) => {
    return stations.find((st) => st.satrec && st.satrec.satnum == id);
  };

  handleStationClicked = (station) => {
    if (!station) return;

    this.toggleSelection(station);
  };

  toggleSelection(station) {
    if (this.state.selected) this.deselectStation(this.state.selected);

    this.selectStation(station);
    // if (this.isSelected(station)) this.deselectStation(station);
    // else this.selectStation(station);
  }

  // isSelected = (station) => {
  //   return this.state.selected.includes(station);
  // };

  selectStation = (station) => {
    this.setState({ selected: station });

    this.engine.cameraLookAt(station);

    this.engine.addOrbit(station);
  };

  deselectStation = (station) => {
    this.setState({ selected: null });

    this.engine.removeOrbit(station);
  };

  addStations = () => {
    this.engine.loadTleStation().then((station) => {
      this.handleStationClicked(station);
      this.setState({ stations: [station] });
      this.processQuery([station]);
    });
  };

  handleTimer = () => {
    // By default, update in realtime every second, unless dateSlider is displayed.
    if (!UseDateSlider) this.handleDateChange(null, new Date());
  };

  // handleSearchResultClick = (station) => {
  //   if (!station) return;

  //   this.toggleSelection(station);
  // };

  handleRemoveSelected = (station) => {
    // if (!station) return;

    // this.deselectStation(station);
  };

  // handleRemoveAllSelected = () => {
  //   this.state.selected.forEach((s) => this.engine.removeOrbit(s));
  //   this.setState({ selected: [] });
  //   this.engine.cameraReset();
  // };

  // handleReferenceFrameChange = () => {
  //   this.state.selected.forEach((s) => this.engine.removeOrbit(s));

  //   const newType = this.state.referenceFrame === 1 ? 2 : 1;
  //   this.setState({ referenceFrame: newType });
  //   this.engine.setReferenceFrame(newType);

  //   this.state.selected.forEach((s) => this.engine.addOrbit(s));
  // };

  handleDateChange = (v, d) => {
    const newDate = v ? v.target.value : d;
    this.setState({ currentDate: newDate });

    const date = new Date();
    date.setTime(newDate);
    this.engine.updateAllPositions(date);
    this.engine.updateSunPosition(date);
  };

  renderDate = (v) => {
    const result = new Date();
    result.setTime(v);
    return result.toString();
  };

  updateTime = () => {
    const time = new Date();
    this.setState((prevState) => ({ ...prevState, time }));
  };

  utcDate = () => {
    const { time } = this.state;

    return {
      time: format(time, "ppp", { locale: pt }),
      date: format(time, "PPPP"),
    };
  };

  render() {
    const { selected, initialDate, currentDate } = this.state;

    const maxMs = initialDate + DateSliderRangeInMilliseconds;

    return (
      <div>
        <div className="absolute bottom-6 ml-6 opacity-50">
          <div className="text-3xl text-gray-100">{this.utcDate().time}</div>
          <div className="text-1xl text-gray-100">{this.utcDate().date}</div>
        </div>
        <Logo />
        <Partner />
        <SelectedStations
          selected={selected}
          onRemoveStation={this.handleRemoveSelected}
        />
        {UseDateSlider && (
          <DateSlider
            min={initialDate}
            max={maxMs}
            value={currentDate}
            onChange={this.handleDateChange}
            onRender={this.renderDate}
          />
        )}
        <div
          ref={(c) => (this.el = c)}
          style={{ width: "100%", height: "100%" }}
        />
      </div>
    );
  }
}

export default App;
