import React, { Component } from "react";

class Partner extends Component {
  render() {
    const date = new Date();

    return (
      <div className="absolute right-6 bottom-6">
        <span className="text-sm text-gray-100 opacity-50 hover:text-white hover:opacity-100 cursor-pointer">
          &copy; geosystems @ CEiiA {date.getFullYear()}
        </span>
      </div>
    );
  }
}

export default Partner;
