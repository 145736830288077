import React from "react";

const MaxSearchResults = 100;

const filterResults = (stations, searchText) => {
  if (!stations) return null;
  if (!searchText || searchText === "") return null;

  const regex = new RegExp(searchText, "i");

  return stations
    .filter((station) => regex.test(station.name))
    .slice(0, MaxSearchResults);
};

const SearchResults = ({ stations, searchText, onResultClick }) => {
  const results = filterResults(stations, searchText);
  if (!results) return null;

  return (
    <div>
      {results.map((result, i) => (
        <StationCard
          key={result.name + i}
          station={result}
          onClick={onResultClick}
        />
      ))}
    </div>
  );
};

export const StationCard = ({ station, onClick, onRemoveClick, className }) => {
  const noradId = station.satrec && station.satrec.satnum;

  return (
    // <div className="py-5 border-b border-gray-200 dark:border-gray-700 w-52">
    <div className="py-5 w-52">
      <h1 className="font-medium font-nunito text-7xl cursor-pointer" onClick={(e) => onRemoveClick(station)}>{station.name}</h1>
      {/* <div className="flex justify-between items-center mb-2">
        <p className="text-gray-500 dark:text-gray-400">{station.name}</p>
        <button onClick={(e) => onRemoveClick(station)} className="hover:text-red-400 text-gray-100">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-x"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
      </div>
      <p className="text-gray-500 dark:text-gray-400">
        <span className="text-white">Norad ID: </span>
        
        {noradId}
      </p> */}
    </div>
    // </div>

    // <div
    //   className={"Result " + (className || "")}
    //   onClick={(e) => onClick && onClick(station)}
    // >
    //   <div>
    //     {onRemoveClick && (
    //       <p className="RemoveButton" onClick={(e) => onRemoveClick(station)}>
    //         x
    //       </p>
    //     )}
    //   </div>
    //   <div>
    //     <p>{station.name}</p>
    //   </div>
    // </div>
  );
};

export default SearchResults;
