import { earthRadius } from "satellite.js/lib/constants";
import * as satellite from "satellite.js/lib/index";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

import earthbump from "../assets/earthbump.jpg";
import earthmap from "../assets/earthmap-day.jpg";
import nightmap from "../assets/earthmap-night.jpg";
import earthspecular from "../assets/earthspecular.jpg";
import mh1 from "../assets/mh1.glb";
import milkyway from "../assets/milkyway.jpg";
import CoordStars from "../utils/geographic/coordstars";
import { Fresnel } from "../utils/geographic/fresnel";
import { getPositionFromTle, parseTleFile } from "./tle";

import { addDays } from "date-fns";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

import gsap from "gsap";
import { MeshoptDecoder } from "three/examples/jsm/libs/meshopt_decoder.module";

// Bypass CORS
function getCorsFreeUrl(url) {
  return url;
}
const MinutesPerDay = 1440;
const ixpdotp = MinutesPerDay / (2.0 * 3.141592654);

let TargetDate = new Date();

const defaultOptions = {
  backgroundColor: 0x041119,
  defaultSatelliteColor: 0x00ff00,
  defaultOrbitColor: 0xfa7c2d,
  onStationClicked: null,
};

const defaultStationOptions = {
  orbitMinutes: 0,
  satelliteSize: 70,
};

export class Engine {
  selected = null;
  stations = [];
  referenceFrame = 1;

  animate = () => {
    if (this.selected?.mesh) {
      this.rotateAroundAxis(this.selected, 0);

      const { mesh } = this.selected;

      mesh.quaternion.copy(this.camera.quaternion);

      mesh.rotateX(0.6 * 1000)

      mesh.rotateZ(0.001 * mesh.position.z)

    };

    requestAnimationFrame(this.animate);

    this.controls.update();

    this.renderer.render(this.scene, this.camera);
  };

  initialize(container, options = {}) {
    this.element = container;
    this.raycaster = new THREE.Raycaster();
    this.options = { ...defaultOptions, ...options };

    this._setupScene();
    this._setupLights();
    this._addBaseObjects();

    this.animate();
    // this.render();

    window.addEventListener("resize", this.handleWindowResize);
    window.addEventListener("pointerdown", this.handleMouseDown);
  }

  dispose() {
    window.removeEventListener("pointerdown", this.handleMouseDown);
    window.removeEventListener("resize", this.handleWindowResize);
    //window.cancelAnimationFrame(this.requestID);

    this.raycaster = null;
    this.element = null;

    this.controls.dispose();
  }

  handleWindowResize = () => {
    const width = this.element.clientWidth;
    const height = this.element.clientHeight;

    this.renderer.setSize(width, height);
    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();

    this.render();
  };

  handleMouseDown = (e) => {
    const mouse = new THREE.Vector2(
      (e.clientX / this.element.clientWidth) * 2 - 1,
      -(e.clientY / this.element.clientHeight) * 2 + 1
    );

    this.raycaster.setFromCamera(mouse, this.camera);

    let station = null;

    const intersects = this.raycaster.intersectObjects(
      this.scene.children,
      true
    );
    if (intersects && intersects.length > 0) {
      const picked = intersects[0].object;
      if (picked) {
        station = this._findStationFromMesh(picked);
      }
    }

    const cb = this.options.onStationClicked;
    if (cb) cb(station);
  };

  // __ API _________________________________________________________________

  addSatellite = (station, color, size) => {
    const sat = this._getSatelliteMesh(color, size);
    const pos = this._getSatellitePositionFromTle(station);

    if (!pos) return;

    sat.position.set(pos.x, pos.y, pos.z);

    station.mesh = sat;

    this.stations.push(station);

    if (station.orbitMinutes > 0) this.addOrbit(station);

    this.earth.add(sat);
  };

  createCookie(name, value, date) {
    if (date) {
      var expires = "; expires=" + date.toGMTString();
    } else var expires = "";
    document.cookie = name + "=" + value + expires + "; path=/";
  }

  // loadLteFileStations() {

  // }

  cachedLoadLteFileStations = (text, color, stationOptions) => {
    return new Promise((resolve) => {
      const options = { ...defaultStationOptions, ...stationOptions };

      const parse = parseTleFile(text);

      const tle =
        parse[0].name + "\n" + parse[0].tle1 + "\n" + parse[0].tle2 + "\n";

      setTimeout(() => {
        const stations = this._addTleFileStations(tle, color, options);
        resolve(stations);
      }, 200);
    });
  };

  loadTleStation = async (color, stationOptions) => {
    const cookie = RegExp(new RegExp("(^| )" + "TLE" + "=([^;]+)")).exec(
      document.cookie
    );

    const url = getCorsFreeUrl(
      "https://celestrak.org/NORAD/elements/gp.php?NAME=mh-1&FORMAT=TLE"
    );
    const tle = localStorage.getItem("tle");

    return !cookie
      ? this.loadLteFileStations(url, color, stationOptions)
      : this.cachedLoadLteFileStations(tle, color, stationOptions);
  };

  loadLteFileStations = async (url, color, stationOptions) => {
    const today = new Date();
    const midnight = addDays(today, 1);
    midnight.setHours(0, 0, 0, 0);

    const options = { ...defaultStationOptions, ...stationOptions };

    const res = await fetch(url);
    if (res.ok) {
      return res.text().then((text) => {
        localStorage.setItem("tle", text);
        this.createCookie("TLE", "tle", midnight);

        return this._addTleFileStations(text, color, options);
      });
    }
  };

  addOrbit = (station) => {
    if (station.orbitMinutes > 0) return;

    const revsPerDay = station.satrec.no * ixpdotp;
    const intervalMinutes = 1;
    const minutes = station.orbitMinutes || MinutesPerDay / revsPerDay;
    const initialDate = new Date();

    if (!this.orbitMaterial) {
      this.orbitMaterial = new THREE.LineBasicMaterial({
        color: defaultOptions.defaultOrbitColor,
        opacity: 1.0,
        transparent: true,
      });
    }

    const points = [];

    for (let i = 0; i <= minutes; i += intervalMinutes) {
      const date = new Date(initialDate.getTime() + i * 60000);

      const pos = getPositionFromTle(station, date, this.referenceFrame);
      if (!pos) continue;

      points.push(new THREE.Vector3(pos.x, pos.y, pos.z));
    }

    const geometry = new THREE.BufferGeometry().setFromPoints(points);
    const orbitCurve = new THREE.Line(geometry, this.orbitMaterial);
    station.orbit = orbitCurve;

    this.earth.add(orbitCurve);
    this.render();
  };

  removeOrbit = (station) => {
    if (!station?.orbit) return;

    this.earth.remove(station.orbit);
    this.earth.remove(station.mesh);
    station.orbit.geometry.dispose();
    station.orbit = null;

    this.render();
  };

  highlightStation = (station) => {
    station.mesh.material = this.highlightedMaterial;
  };

  setReferenceFrame = (type) => {
    this.referenceFrame = type;
  };

  _addTleFileStations = (lteFileContent, color, stationOptions) => {
    const stations = parseTleFile(lteFileContent, stationOptions);

    const { satelliteSize } = stationOptions;

    stations.forEach((s) => {
      this.addSatellite(s, color, satelliteSize);
    });

    this.render();

    return stations[0];
  };

  _getSatelliteMesh = () => {
    return this._getSatelliteGltf();
  };

  _getSatelliteGltf() {
    const loader = new GLTFLoader();
    loader.setMeshoptDecoder(MeshoptDecoder);

    const group = new THREE.Group();
    loader.load(mh1, (gltf) => {
      gltf.scene.traverse((obj) => {
        if (obj instanceof THREE.Mesh) {
          const mesh = obj.clone();

          group.add(mesh);
        }
      });
    });

    group.scale.setScalar(defaultStationOptions.satelliteSize / 100);

    return group;
  }

  _getSatellitePositionFromTle = (station, date) => {
    date = date || TargetDate;
    return getPositionFromTle(station, date, this.referenceFrame);
  };

  updateSatellitePosition = (station, date) => {
    date = date || TargetDate;

    const pos = getPositionFromTle(station, date, this.referenceFrame);
    if (!pos) return;

    station.mesh.position.set(pos.x, pos.y, pos.z);
  };

  updateSunPosition = (date) => {
    const coSun = CoordStars.getSunPositionInSceneAtTime(date);
    const euler = CoordStars.getSunEuler(date);

    //TODO: not perfectly correct sun position
    this.sun.position.set(...coSun);

    this.sun.setRotationFromEuler(euler);
  };

  updateAllPositions = (date) => {
    if (!this.stations) return;

    this.stations.forEach((station) => {
      this.updateSatellitePosition(station, date);
    });

    if (this.referenceFrame === 2) this._updateEarthRotation(date);
    else this.render();
  };

  _updateEarthRotation = (date) => {
    const gst = satellite.gstime(date);
    this.earthMesh.setRotationFromEuler(new THREE.Euler(0, gst, 0));

    this.render();
  };

  // __ Scene _______________________________________________________________

  _setupScene = () => {
    const width = this.element.clientWidth;
    const height = this.element.clientHeight;

    this.scene = new THREE.Scene();

    this._setupCamera(width, height);

    this.renderer = new THREE.WebGLRenderer({
      logarithmicDepthBuffer: true,
      antialias: true,
    });

    new THREE.TextureLoader().load(milkyway, (texture) => {
      texture.mapping = THREE.EquirectangularReflectionMapping;
      texture.format = THREE.RGBAFormat;
      texture.generateMipmaps = true;
      texture.magFilter = THREE.NearestFilter;
      texture.minFilter = THREE.LinearFilter;

      this.scene.background = texture;
      this.scene.environment = texture;
    });

    this.renderer.setClearColor(new THREE.Color(this.options.backgroundColor));
    this.renderer.setSize(width, height);

    this.element.appendChild(this.renderer.domElement);
  };

  _setupCamera(width, height) {
    const NEAR = 1e-6,
      FAR = 1e27;
    this.camera = new THREE.PerspectiveCamera(54, width / height, NEAR, FAR);
    this.controls = new OrbitControls(this.camera, this.element);

    this.controls.enableDamping = true;
    this.controls.enablePan = false;
    this.controls.maxDistance = 20000;
    this.controls.minDistance = 8000;

    this.controls.addEventListener("change", this.render);
    this.camera.position.z = -15000;
    this.camera.position.x = 15000;
    this.camera.lookAt(0, 0, 0);
  }

  _setupLights = () => {
    const coSun = CoordStars.getSunPositionInSceneAtTime();
    const euler = CoordStars.getSunEuler();

    this.sun = new THREE.PointLight(0xffffff, 1, 0);

    //TODO: not perfectly correct sun position
    this.sun.position.set(...coSun);

    this.sun.setRotationFromEuler(euler);

    const ambient = new THREE.AmbientLight(0x909090);

    this.scene.add(this.sun);
    this.scene.add(ambient);
  };

  _addBaseObjects = () => {
    this._addEarth();
  };

  rotateAroundAxis(station, duration, onComplete) {
    const { position } = station.mesh;

    let spherical = new THREE.Spherical();
    let startPos = new THREE.Vector3();
    let endPos = new THREE.Vector3();
    let axis = new THREE.Vector3();
    let tri = new THREE.Triangle();

    spherical.setFromVector3(this.camera.position);
    spherical.phi = 0;
    spherical.makeSafe();
    endPos.setFromSpherical(spherical);

    startPos.copy(this.camera.position);

    tri.set(position, this.scene.position, startPos);
    tri.getNormal(axis);

    let angle = startPos.angleTo(position);

    let value = { value: 0 };

    gsap.to(value, {
      value: 1,
      duration,
      onUpdate: () => {
        this.camera.position
          .copy(startPos)
          .applyAxisAngle(axis, angle * value.value);
      },
      onComplete,
    });
  }

  cameraLookAt(station) {
    this.rotateAroundAxis(station, 2, () => (this.selected = station));
  }

  render = () => {
    this.renderer.render(this.scene, this.camera);

    //this.requestID = window.requestAnimationFrame(this._animationLoop);
  };

  // __ Scene contents ______________________________________________________

  _addEarth = () => {
    const textureLoader = new THREE.TextureLoader();

    const map = textureLoader.load(earthmap, this.render);
    const specularMap = textureLoader.load(earthspecular, this.render);
    const bumpMap = textureLoader.load(earthbump, this.render);

    map.minFilter = THREE.LinearFilter;

    const group = new THREE.Group();

    // Planet
    let geometry = new THREE.SphereGeometry(earthRadius, 50, 50);
    let material = new THREE.MeshPhongMaterial({
      displacementMap: specularMap,
      displacementScale: -30,
      specularMap,
      bumpMap,
      bumpScale: 130,
      side: THREE.DoubleSide,
      flatShading: false,
      map,
    });

    this.earthMesh = new THREE.Mesh(geometry, material);
    // this.nightMesh = this._addNightLight(textureLoader, geometry);
    this.atmosphereMesh = this._addAtmosphere(geometry);

    group.add(this.earthMesh);
    // group.add(this.nightMesh);
    group.add(this.atmosphereMesh);

    this.earth = group;

    this.scene.add(this.earth);
  };

  _addAtmosphere = (geometry) => {
    const atmosphere = new Fresnel();

    const material = atmosphere.material();
    const mesh = new THREE.Mesh(geometry, material);
    mesh.scale.setScalar(1.01);

    return mesh;
  };

  _addNightLight = (textureLoader, geometry) => {
    const material = new THREE.MeshBasicMaterial({
      map: textureLoader.load(nightmap),
      blending: THREE.AdditiveBlending,
    });

    return new THREE.Mesh(geometry, material);
  };

  _findStationFromMesh = (threeObject) => {
    for (const element of this.stations) {
      const s = element;

      if (s.mesh === threeObject) return s;
    }

    return null;
  };
}
