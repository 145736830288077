import * as THREE from "three";

import GlowFS from '../shaders/GlowFS.glsl';
import GlowVS from '../shaders/GlowVS.glsl';

export class Fresnel {
  material({ rimHex = 0x0088ff, facingHex = 0x000000 } = {}) {
    const uniforms = {
      color1: { value: new THREE.Color(rimHex) },
      color2: { value: new THREE.Color(facingHex) },
      fresnelBias: { value: 0.1 },
      fresnelScale: { value: 1.0 },
      fresnelPower: { value: 4.0 },
    };
    return new THREE.ShaderMaterial({
      uniforms: uniforms,
      vertexShader: GlowVS,
      fragmentShader: GlowFS,
      transparent: true,
      blending: THREE.AdditiveBlending
    });
  }
}
