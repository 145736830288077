import React from "react";
import { StationCard } from "../Search/SearchResults";

export default function ({
  selected,
  onRemoveStation,
  onStationClick,
}) {
  if (!selected || selected.length === 0) return null;
  return (
    <div className="absolute ml-6 mt-6 opacity-50 hover:text-white hover:opacity-100">
      <StationCard
        station={selected}
        onRemoveClick={onRemoveStation}
        onClick={onStationClick}
      />
     </div>
  );
}
